<template>
  <md-table v-model="cadastro" @md-selected="onSelect">
    <div v-if="!isFetching">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <div style="padding-top:15px;display:inline-block;">
            <span>
              <h5 class="title" style="font-weight:350;padding-bottom: 10px;">
                Entidade
              </h5></span
            >
          </div>
          <div id="agrupamentoCampos" class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-33">
              <ValidationProvider
                name="form.nomeEntidade"
                rules="required"
                v-slot="{ passed, failed, errors }"
              >
                <md-autocomplete
                  v-model="entidadeSelect"
                  :md-options="entidadeSelectOptionAuto"
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                  @md-selected="selectedEntidade"
                >
                  <label>Selecionar Entidade</label>
                </md-autocomplete>

                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
          </div>
          <br />
          <span>
            <h5 class="title" style="font-weight:350;padding-bottom: 10px;">
              Dados Pessoais
            </h5></span
          >
          <div id="agrupamentoCampos" class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-100">
              <ValidationProvider
                name="form.nomeCompleto"
                rules="required|min:3|max:100"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Nome Completo</label>
                  <md-input
                    name="Nome Completo"
                    v-model="form.nomeCompleto"
                    type="text"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="form.cpfPensionista"
                :rules="{
                  regex: /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/,
                  min: 14,
                  max: 14,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>CPF</label>
                  <md-input
                    v-model="form.cpfPensionista"
                    type="text"
                    :maxlength="14"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ "CPF dever ser no formato 999.999.999-99" }}
                </span>
              </ValidationProvider>
            </div>

            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="form.dataNascimento"
                :rules="{
                  regex: /([0-2]\d{1}|3[0-1])\/(0\d{1}|1[0-2])\/(19|20)(\d{2})/,
                  min: 10,
                  max: 10,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Data de Nascimento</label>
                  <md-input
                    v-model="form.dataNascimento"
                    type="text"
                    :maxlength="10"
                    :key="componentKeyToRerender"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ "Data deve ter formato DD/MM/AAAA" }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="form.diaAniversario"
                :rules="{
                  regex: /^(0[1-9]|1[0-9]|2[0-9]|3[0-1])$/,
                  min: 2,
                  max: 2,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Dia Aniversário</label>
                  <md-input
                    v-model="form.diaAniversario"
                    type="text"
                    :maxlength="2"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="form.mesAniversario"
                :rules="{
                  regex: /^(0[1-9]|1[0-2])$/,
                  min: 2,
                  max: 2,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Mês de Aniversário</label>
                  <md-input
                    v-model="form.mesAniversario"
                    type="text"
                    :maxlength="2"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-10">
              <md-field>
                <label>Idade</label>
                <md-input
                  v-model="idade"
                  type="numeric"
                  :maxlength="3"
                  disabled
                ></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-33">
              <ValidationProvider
                name="form.sexoPensionista"
                rules="required"
                v-slot="{ errors }"
              >
                <label>Sexo </label>
                <md-radio v-model="form.sexoPensionista" value="M"
                  >Masculino</md-radio
                >
                <md-radio v-model="form.sexoPensionista" value="F"
                  >Feminino</md-radio
                >
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  ><br />{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
          </div>
          <br />
          <div class="md-layout">
            <div class="md-layout-item md-size-100 text-right">
              <md-button type="submit" class="md-raised md-success mt-4">
                CRIAR
              </md-button>
              &nbsp;
              <md-button
                @click="validarProximo()"
                class="md-raised md-success mt-4"
              >
                PROXIMO >
              </md-button>
              &nbsp;
              <md-button
                type="button"
                class="md-raised mt-4"
                @click="cancelarButton"
              >
                CANCELAR
              </md-button>
              <span>&nbsp;</span>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </md-table>
</template>
<script>
import { extend, localize } from "vee-validate";
import pt_BR from "vee-validate/dist/locale/pt_BR.json";
import { store } from "../../../store";
import { mapState, mapActions, mapGetters } from "vuex";
import * as rules from "vee-validate/dist/rules";
import { NavTabsCard } from "@/components";
import Vue from "vue";
import Swal from "sweetalert2";
import { cpfMask } from "@/pages/Dashboard/js/cpfMask";
import { dataMask } from "@/pages/Dashboard/js/dataMask";
import { NOME_CAMPOS } from "@/pages/Dashboard/js/nomeCampos";
import { checkAllResponsesPensionista } from "../js/checkAllResponsesPensionista";

var pt_br = pt_BR;
var names = NOME_CAMPOS;
Vue.set(pt_br, "names", names);
for (let key in pt_br.messages) {
  if (key === "regex") {
    pt_br.messages.regex = "Campo com formato inválido.";
  }
}
for (let key in pt_br.messages) {
  extend(key, rules[key]);
}
localize("pt_BR", pt_br);

export default {
  /* props: {
    id: {
      type: String
    },
    isEdition: {
      type: Boolean
    }
  },*/
  computed: {
    ...mapState("auth", ["user_profile"]),
    ...mapGetters("pensionista", {
      pensionistaSelected: "pensionistaState"
    }),
    user_profile_state: state => state.user_profile
  },
  data() {
    return {
      componentKeyToRerender: 0,
      entidadeSelect: "",
      entidadeSelectOptionAuto: [],
      entidadeSelectOption: [],
      selectedPensionistaId: this.id,
      cadastro: null,
      isFetching: true,
      isFetching3: true,
      showButton: false,
      editionMode: false,
      isDisabledEdition: true,
      idade: 0,
      radioSexo: false,
      status: false,
      mensagem: "",
      form: {
        idPensionista: this.id,
        idEntidade: null,
        nomeEntidade: null,
        nomeCompleto: null,
        cpfPensionista: null,
        dataNascimento: null,
        diaAniversario: null,
        mesAniversario: null,
        sexoPensionista: null
      },
      papel: ""
    };
  },
  beforeMount() {
    this.getPapel();
    this.getEntidadeAll().then(() => {
      this.loadPensionista();
    });
  },
  methods: {
    ...mapActions("papel", ["ActionGetPapelAll"]),
    ...mapActions("pensionista", [
      "ActionSetPENSIONISTA",
      "ActionPUTPensionista",
      "ActionSubmitPensionistaAllData"
    ]),
    ...mapActions("entidade", [
      "ActionGetEntidadeById",
      "ActionGetEntidadeAll"
    ]),
    onSelect() {
      console.log("Novo Cadastro Pensionista");
    },
    forceRerender() {
      this.componentKeyToRerender += 1;
    },
    async loadForm() {
      if (this.pensionistaSelected.idPensionista) {
        this.form.idPensionista = this.pensionistaSelected.idPensionista;
      }
      this.form.idEntidade = this.pensionistaSelected.idEntidade;
      this.form.nomeEntidade = this.pensionistaSelected.nomeEntidade;

      this.form.nomeCompleto = this.pensionistaSelected.nomeCompleto;

      if (
        this.pensionistaSelected.cpfPensionista !== "" &&
        this.pensionistaSelected.cpfPensionista !== null &&
        typeof this.pensionistaSelected.cpfPensionista !== "undefined"
      ) {
        this.form.cpfPensionista = cpfMask(
          this.pensionistaSelected.cpfPensionista.toString()
        );
      }

      this.form.dataNascimento =
        this.pensionistaSelected.dataNascimento !== "" &&
        this.pensionistaSelected.dataNascimento !== null &&
        typeof this.pensionistaSelected.dataNascimento !== "undefined"
          ? this.pensionistaSelected.dataNascimento.toString().trim()
          : null;

      // this.form.dataNascimento = this.pensionistaSelected.dataNascimento;

      if (
        this.form.dataNascimento !== "" &&
        this.form.dataNascimento !== null &&
        typeof this.servidorPensionista !== "undefined"
      ) {
        let diaMes = this.form.dataNascimento.split("/");
        this.form.diaAniversario = diaMes[0];
        this.form.mesAniversario = diaMes[1];
        this.idade = this.callIdadeCalc(this.form.dataNascimento);
      }

      this.radioSexo = this.pensionistaSelected.sexoPensionista;
      this.form.sexoPensionista = this.pensionistaSelected.sexoPensionista;

      if (this.entidadeSelectOption.length > 0) {
        //this.entidadeSelect = this.form.idEntidade;
        this.entidadeSelect = this.form.nomeEntidade;
      }

      this.isFetching = false;
      if (this.isEdition) {
        this.editionMode = true;
      }
      let copiedJSON = Object.keys(this.entidadeSelectOption).map(key => {
        return this.entidadeSelectOption[key].nomeEntidade;
      });
      this.entidadeSelectOptionAuto = copiedJSON;
    },

    async loadPensionista() {
      this.loadForm();
    },
    async getEntidadeById(idEntidade) {
      try {
        let id = idEntidade;
        let papel = this.papel;
        let response = await this.ActionGetEntidadeById({ id, papel });
        if (response.status === 200) {
          if (response.body.mensagem) {
            this.mensagem = response.body.mensagem;
            if (this.mensagem.includes("não encontrada")) {
              Swal.fire({
                title: `idEntidade: ${idEntidade} não encontrada.`,
                icon: "error",
                text: this.mensagem,
                text: `${this.mensagem}`,
                customClass: {
                  confirmButton: "md-button md-raised md-danger btn-fill"
                },
                buttonsStyling: false
              });
            } else {
              Swal.fire({
                icon: "error",
                title: `ERROR - idEntidade: ${idEntidade}`,
                text: `${this.mensagem}`,
                customClass: {
                  confirmButton: "md-button md-raised mt-4 md-success btn-fill"
                },
                buttonsStyling: false
              });
            }
          } else {
            return response.body;
          }
        }
      } catch (err) {
        console.log(err);
        this.handleError(err);
      }
    },
    getPapel() {
      this.papel = this.user_profile_state.papel;
    },
    calcularIdade(dobString) {
      let dob = new Date(dobString);
      let currentDate = new Date();
      let currentYear = currentDate.getFullYear();
      let birthdayThisYear = new Date(
        currentYear,
        dob.getMonth(),
        dob.getDate()
      );
      let age = currentYear - dob.getFullYear();
      if (birthdayThisYear > currentDate) {
        age--;
      }
      return age;
    },
    callIdadeCalc(dataNascimento) {
      if (dataNascimento.toString().length < 10) {
        return 0;
      }
      let data = dataNascimento;
      let partes = data.split("/");
      let join = partes[2] + "-" + partes[1] + "-" + partes[0];
      return this.calcularIdade(join);
    },
    VerificaCPF(strCPF) {
      let Soma;
      let Resto;
      Soma = 0;
      if (strCPF == "00000000000") return false;

      for (i = 1; i <= 9; i++)
        Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
      Resto = (Soma * 10) % 11;

      if (Resto == 10 || Resto == 11) Resto = 0;
      if (Resto != parseInt(strCPF.substring(9, 10))) return false;

      Soma = 0;
      for (i = 1; i <= 10; i++)
        Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
      Resto = (Soma * 10) % 11;

      if (Resto == 10 || Resto == 11) Resto = 0;
      if (Resto != parseInt(strCPF.substring(10, 11))) return false;
      return true;
    },
    checkEditionDisabled() {
      if (this.isDisabledEdition === true) {
        return "disabled";
      } else {
        return null;
      }
    },
    async submit() {
      try {
        this.ActionSetPENSIONISTA(this.form);
        var papel = this.papel;
        var form = this.form;
        this.ActionSubmitPensionistaAllData({
          papel
        }).then(response => {
          checkAllResponses(response);
        });
        /*var response = await this.ActionPOSTServidorPublico({
          papel,
          form
        });*/
      } catch (err) {
        this.handleError(err);
      }
    },
    emitToParent(value) {
      this.$emit("nextRoute", value);
    },
    cancelarButton() {
      this.resetForm();
    },
    selectedEntidade(item) {
      let nomeEntidade = item;
      let index = -1;
      let resultItem = this.entidadeSelectOption.find(function(item, i) {
        if (item.nomeEntidade === nomeEntidade) {
          index = i;
          return item;
        }
      });
      this.form.idEntidade = resultItem.idEntidade;
      this.form.nomeEntidade = item;
    },
    async getEntidadeAll() {
      try {
        let papel = this.papel;
        await this.ActionGetEntidadeAll({ papel }).then(res => {
          let result = res.body;
          result.sort((a, b) => {
            if (a.nomeEntidade < b.nomeEntidade) return -1;
            if (a.nomeEntidade > b.nomeEntidade) return 1;
            return 0;
          });
          this.entidadeSelectOption = result;
          this.isFetching3 = false;
          return this.entidadeSelectOption;
        });
      } catch (err) {
        console.log(err);
        this.handleError(err);
      }
    },
    handleError(err) {
      console.log(err);
      if (
        err === 400 ||
        err === 500 ||
        err === 404 ||
        err.status === 400 ||
        err.status === 500 ||
        err.status === 404
      ) {
        this.mensagem = "Aplicação temporariamente indisponível.";
      } else if (err.status === 401 || err.data === "Unauthorized") {
        this.mensagem = "Operação não Autorizada";
      } else {
        if (err.body) {
          this.mensagem = err.body.mensagem;
        } else if (err.status === 0) {
          this.mensagem =
            "Verifique sua conexão com a internet ou aguarde restabelecer conexões.";
        } else if (err.status === 409) {
          this.mensagem = err.body.mensagem;
        } else {
          this.mensagem =
            "Operação temporariamente indisponível tente mais tarde.";
        }
      }
      Swal.fire({
        title: "Erro!",
        icon: "error",
        text: this.mensagem,
        customClass: {
          confirmButton: "md-button md-danger btn-fill"
        },
        buttonsStyling: false
      });
    },
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    validarProximo() {
      let checkerror = true;
      this.$refs.observer.validate();
      this.delay(1000).then(() => {
        if (this.$refs.observer.errors) {
          checkerror = Object.keys(this.$refs.observer.errors).some(key => {
            let checkerrorArray =
              this.$refs.observer.errors[key].length > 0 ? true : false;
            return checkerrorArray;
          });
          if (!checkerror) {
            this.ActionSetPENSIONISTA(this.form);
            this.emitToParent("pensao");
            window._Vue.$router.push({ name: "Nova Pensao" }).catch(err => {});
          }
        }
      });
    },
    resetForm() {
      Object.keys(this.form).map(key => {
        return (this.form[key] = "");
      });
      this.entidadeSelect = undefined;
      requestAnimationFrame(() => {
        this.$refs.observer.reset();
      });
    }
  },
  watch: {
    "form.cpfPensionista": function(val, oldVal) {
      let cpf = cpfMask(val);
      this.form.cpfPensionista = cpf;
    },
    "form.dataNascimento": function(val, oldVal) {
      let dataNAsc = dataMask(val.toString());
      this.form.dataNascimento = dataNAsc;
      this.idade = this.callIdadeCalc(this.form.dataNascimento);
      let diaMes = this.form.dataNascimento.split("/");
      this.form.diaAniversario = diaMes[0];
      this.form.mesAniversario = diaMes[1];
    }
  }
};
</script>

<style lang="css" scoped>
::v-deep .paginated-table .md-table-head-label {
  font-size: 0.875rem;
  font-weight: 400;
}
.md-table-cell {
  font-size: 12px;
}
#error {
  color: red;
  font-weight: 400;
  font-size: 0.65rem;
  margin-top: 0;
  transition: all 0.2s ease-in-out;
}

.md-card .md-card-actions {
  border: none;
  margin-left: 20px;
  margin-right: 20px;
}
.space-tool-bar {
  width: 50px;
  height: 50px;
}

.md-button.md-info.md-simple,
.md-button.md-info.md-simple:hover,
.md-button.md-info.md-simple:focus,
.md-button.md-info.md-simple:active,
.md-button.md-info.md-simple.active,
.md-button.md-info.md-simple:active:focus,
.md-button.md-info.md-simple:active:hover,
.md-button.md-info.md-simple.active:focus,
.md-button.md-info.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}

.md-button.md-success.md-simple,
.md-button.md-success.md-simple:hover,
.md-button.md-success.md-simple:focus,
.md-button.md-success.md-simple:active,
.md-button.md-success.md-simple.active,
.md-button.md-success.md-simple:active:focus,
.md-button.md-success.md-simple:active:hover,
.md-button.md-success.md-simple.active:focus,
.md-button.md-success.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}
.paginated-table .md-table-cell-container .md-button .md-button-content i {
  font-size: 24px !important;
}
::v-deep .modal-container {
  max-width: 720px;
}

.modal-text {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif !important;
  font-size: 1.125em;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

::v-deep .md-count {
  display: none;
}

#agrupamentoCampos {
  border: 1px solid rgba(128, 128, 128, 0.3);
  border-radius: 5px;
  padding-top: 12px;
  padding-bottom: 12px;
}

::v-deep .md-radio.md-disabled .md-radio-label,
.md-radio.md-disabled .md-radio-container {
  opacity: 1;
  border-color: rgba(0, 0, 0, 1) !important;
}

::v-deep .md-input {
  text-transform: uppercase;
}

::v-deep .md-switch-label {
  font-weight: bold;
  color: rgba(14, 14, 14, 0.52);
}

::v-deep .md-switch.md-checked .md-switch-container {
  background-color: #49a54d !important;
}

::v-deep .md-switch.md-checked .md-switch-thumb {
  border: 1px solid #49a54d;
}

::v-deep .md-radio.md-checked .md-radio-container {
  border-color: #000000 !important;
}

::v-deep .md-radio.md-checked .md-radio-container:after {
  background-color: #000000 !important;
}

.switch-div {
  /*display: inline-block;*/
  float: right;
}
</style>
